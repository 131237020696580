<template>
  <div class="d-flex flex-column">
    <a-row style="margin-top: 20px">
      <h1>{{ $route.meta.title[$i18n.locale] }}</h1>
    </a-row>
    <imageStore />
  </div>
</template>

<script>
export default {
  components: {
    imageStore: () => import("@/components/imageStorage/imageStorage")
  }
}
</script>
<style></style>
